import styled, { css } from "styled-components";
import { Container } from "../../../site-pages/MarketingPage/styles/global";

export const Nav = styled.nav`
  display: flex;
  min-width: 490px;
  background: ${(props) => (props.scrolled ? "white" : "transparent")};
  padding: ${(props) => (props.scrolled ? "16px 0" : "24px 0")};
  margin-bottom: ${(props) => (props.scrolled ? "0" : "0")};
  position: sticky;
  top: 0;
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
  z-index: 1000;
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-size: 0.9rem;
  font-weight: 600;

  a {
    text-decoration: none;
    text-transform: capitalize;
    opacity: 0.9;
    color: ${(props) => (props.scrolled ? "black" : "white")};
  }

  &.active {
    a {
      opacity: 1;
    }
  }
`;

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      css`
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`;

export const MobileMenu = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: ${(props) => props.theme.color.regular};
`;

export const Brand = styled.div`
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      text-decoration: none;
      img {
        margin-top: 0.4rem;
        width: 10.5rem;
        height: auto;
      }
    }
  }
`;
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    padding: 0.5rem 1rem;
    margin-right: 0;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    > div {
      // display: none;
    }
  }
`;

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    display: block;
  }

  ${(props) =>
    props.hide &&
    css`
      display: block;

      @media (max-width: ${props.theme.screen.xs}) {
        display: none;
      }
    `}
`;
