import Head from "next/head";

const metadata = {
  title: "Preneur - All-in-one platform - Promote, capture & sell.",
  description:
    "Preneur is an all-in-one platform designed to help solo entrepreneurs succeed. Promote, capture & sell more.  Get started with free tools, and upgrade as you grow.",
  image:
    "https://res.cloudinary.com/scall/image/upload/v1693182461/WEB-OG_ebftth.png",
};

const PageHead = () => {
  const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;
  return (
    <Head>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta property="og:title" content={metadata.title} />
      <meta property="og:description" content={metadata.description} />
      <meta
        name="facebook-domain-verification"
        content="mt4c9v6acqlazo66cs8me0psm1jlv1"
      />
      <meta property="og:image" content={metadata.image} />
      <link rel="stylesheet" href="https://use.typekit.net/zdc6ksq.css" />
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <script
        async
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: ` 
          window.dataLayer = window.dataLayer || [];
          function getTag(){dataLayer.push(arguments);}
          getTag('js',new Date());
          getTag('config', '${GTM_ID}',{
            page_path: window.location.pathname,
          });
          `,
        }}
      />
    </Head>
  );
};

export default PageHead;
