import styled from "styled-components";
import { Container } from "../../site-pages/MarketingPage/styles/global";

export const HeadingGroup = styled(Container)`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const Heading = styled.h1`
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 3.5rem;
  text-align: center;
  color: black;

  margin: 0;
  max-width: 650px;
`;

export const SubHeading = styled.p`
  text-align: center;
  color: black;
  max-width: 650px;
`;

export const FooterWrapper = styled.footer`
  background-color: black;
  color: var(--text-light-color);
  padding: 80px 0;
`;

export const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`;

export const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`;
export const FooterColumn = styled.div`
  span {
    display: block;
    font-size: 0.9rem;
    color: var(--text-light-color);
    font-weight: 600;
    margin-bottom: 1rem;
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: var(--marketing-secondary-text-color);
    li {
      margin-bottom: 12px;
      font-size: 15px;

      a {
        text-decoration: none;
        color: var(--marketing-secondary-text-color);
        transition: 0.2s ease-in all;

        &:hover {
          color: ${(props) => props.theme.color.brand};
        }
      }
    }
  }
`;

export const StyledCompetitorsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 3rem;
  padding: 2rem 1rem;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  position: relative;

  &:before {
    content: "VS";
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;

    background-color: var(--brand-color);
    border-radius: 50%;
    border: 1px solid var(--border-color);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    &:before {
      top: 50%;
    }
  }
`;

export const StyledCompetitorColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  padding: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 2rem;
  background-color: white;
  min-height: 300px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.025);
`;

export const StyledCompetitorColumnFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-top: 1px solid var(--border-color);
  margin-top: auto;
  padding: 1.5rem 0 0;
`;

export const StyledCompetitorItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
`;

export const StyledCompetitorItemImage = styled.div`
  width: 3rem;
  height: 3rem;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

export const StyledCompetitorItemContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  color: var(--text-secondary-color);
  gap: 0.5rem;
  flex: 1 1 auto;
`;

export const StyledCompetitorItemTitle = styled.p`
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  margin: 0;
`;

export const StyledCompetitorItemText = styled.p`
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 300;
  margin: 0;
`;

export const StyledCompetitorItemPrice = styled.div`
  display: flex;
  flex-flow: column nowrap;
  color: var(--text-color);
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export const StyledFeatureList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding-left: 2rem;
`;

export const StyledFeatureItem = styled.p`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;

  color: var(--text-secondary-color);
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  margin: 0;
`;
