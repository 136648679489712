import Link from "next/link";
import PropTypes from "prop-types";
import links from "./downloadData";
import { DownLoadContainer, Img } from "./styles";

const AppDownload = ({ footer, successPage }) => (
  <DownLoadContainer footer={footer} successPage={successPage}>
    {links.map((link) => (
      <Link href={link.linkUrl} key={link.position}>
        <Img src={link.imgUrl} footer={footer} className="appdownload" />
      </Link>
    ))}
  </DownLoadContainer>
);

AppDownload.propTypes = {
  footer: PropTypes.bool,
  successPage: PropTypes.string,
};

AppDownload.defaultProps = {
  footer: false,
};

export default AppDownload;
