import { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Scrollspy from "react-scrollspy";
// import AppDownload from "../../AppDownload";
import { Container } from "../../../site-pages/MarketingPage/styles/global";
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  ActionsContainer,
} from "./style";

const NAV_ITEMS = [
  {
    label: "Why Preneur",
    id: "why-Preneur",
  },
  {
    label: "What's Included",
    id: "features",
  },
  {
    label: "Blog",
    id: "scall-blog",
  },
];

export default class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      mobileMenuOpen: false,
      hasScrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true });
    } else if (scrollTop < 16) {
      this.setState({ hasScrolled: false });
    }
  };

  // toggleMobileMenu = () => {
  //   this.setState((prevState) => ({
  //     mobileMenuOpen: !prevState.mobileMenuOpen,
  //   }));
  // };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavAnchorLink = (item) => (
    <AnchorLink
      href={`#${item.id.toLowerCase()}`}
      onClick={this.closeMobileMenu}
    >
      {item.label}
    </AnchorLink>
  );

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map((item) => item.id.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map((navItem) => (
          <NavItem scrolled={this.state.hasScrolled} key={navItem.id}>
            {this.getNavAnchorLink(navItem)}
          </NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;

    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled}>
        <StyledContainer>
          <Brand>
            <Scrollspy offset={-64} item={["top"]} currentClassName="active">
              <a href="/" onClick={this.closeMobileMenu}>
                {this.state.hasScrolled ? (
                  <img
                    alt="Preneur Logo"
                    src="/assets/images/Preneur_Logo_Dark_AI_fgltca.png"
                  />
                ) : (
                  <img
                    alt="Preneur Logo"
                    src="/assets/images/Preneur_Logo_Light_AI_hyy3tf.png"
                  />
                )}
              </a>
            </Scrollspy>
          </Brand>
          <ActionsContainer>
            {/* <ScallVideo
              clear={!this.state.hasScrolled}
              videoUrl={videoUrl}
            /> */}
            {/* <Button label="Request Access" URL={CTALink} /> */}
            {/* <AppDownload /> */}
          </ActionsContainer>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}
