import {
  FooterWrapper,
  BrandContainer,
  FooterColumnContainer,
  FooterColumn,
} from "./styles";
import AppDownload from "../AppDownload";

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
      <FooterColumn>
        <span>Download App</span>
        <AppDownload footer />
      </FooterColumn>
      <FooterColumn>
        <span>Preneur</span>
        <ul>
          {/* <li>
            <a href="/hustle-story/brian-white">Blog</a>
          </li> */}
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms-&-conditions">Terms &amp; Conditions</a>
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Contact</span>
        <ul>
          <li>hello@preneur.ai</li>
          <li>
            6 Liberty Square #2186 <br />
            Boston MA 02109
            <br />
            United States
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Social</span>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/preneur.ai/"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/preneur-hq"
            >
              LinkedIn
            </a>
          </li>
          {/* <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/preneur_me"
            >
              Twitter
            </a>
          </li> */}
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/preneur.hq"
            >
              Facebook
            </a>
          </li>
        </ul>
      </FooterColumn>
    </FooterColumnContainer>
    <BrandContainer>
      <a href="/">
        <img
          width={180}
          height={35}
          src="/assets/images/Preneur_Logo_Light_AI_hyy3tf.png"
          alt="PreneurLogo"
        />
      </a>
    </BrandContainer>
  </FooterWrapper>
);

export default Footer;
