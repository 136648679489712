import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import theme from "../../site-pages/MarketingPage/styles/theme";
import GlobalStyles from "../../site-pages/MarketingPage/styles/GlobalStyles";

const MarketingLayout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      {children}
    </>
  </ThemeProvider>
);

MarketingLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MarketingLayout;
