import styled, { css } from "styled-components";

export const DownLoadContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  justify-content: center;
  margin: 1rem auto;

  ${(props) =>
    props.footer &&
    css`
      flex-flow: column nowrap;
      align-items: flex-start;
      gap: 0.5rem;
      margin: 0;
    `}
`;
export const Img = styled.img`
  display: block;
  object-fit: contain;
  cursor: pointer;
  width: 10rem;

  ${(props) =>
    props.footer &&
    css`
      margin: 0;
      margin-bottom: 1rem;
      width: 8rem;
    `}
`;
