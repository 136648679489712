const links = [
  {
    position: 0,
    imgUrl: "/assets/images/badge-apple.svg",
    linkUrl: "https://scall.link/ios",
  },
  {
    position: 1,
    imgUrl: "/assets/images/badge-google.svg",
    linkUrl: "https://scall.link/googleplay",
  },
];

export default links;
